import { useState, useEffect, forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { useGlobalState } from '../GlobalStateContext';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface SnackBarProps {
  message?: string;
  visible: boolean;
  severity?: AlertColor;
}

export const SnackBar: React.FC<SnackBarProps> = ({ visible, message, severity }) => {

  const [open, setOpen] = useState(visible);
  const { setSnackBar } = useGlobalState();

  // Synchronize open state with visible prop
  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar({ visible: false });
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

