import { CentraleData, CommandName } from "../types";

import { ApiClientService } from '.';
import { AlertColor } from "@mui/material";


export interface Command {
  commandName: CommandName;
  parameters: Array<string>;
}

interface CommandStorage {
  centraleId: string;
  command: Command;
}
const sendCommand = async (centraleId:string,command: Command):Promise<CommandResponse>  => {
  const result = await ApiClientService.axios.post<CommandStorage>(`/command/${centraleId}`,command);

  if(result.status !== 200)
    {
        return {
          color: "success",
          message: "Erreur Serveur"
        };

    }

    return {
      color: "success",
      message: "Commande envoyée"
    };
}

export interface CommandResponse
{
  color: AlertColor;
  message : string;
}

const startClockSync = async (centraleId: string) =>
{
  const command: Command = 
  {
    commandName: CommandName.SYNC_CLOCK,
    parameters: []
  }

  return await sendCommand(centraleId, command);
}

const startCleanFiles = async (centraleId: string) => 
{
  const command: Command = 
  {
    commandName: CommandName.CLEAN_FILE_SYSTEM,
    parameters: []
  }

  return await sendCommand(centraleId, command);
}

const startDumpFile = async (centraleId: string, fileName: string) =>
{
  const command: Command = 
  {
    commandName: CommandName.DUMP_FILE,
    parameters: [fileName]
  }

  return await sendCommand(centraleId, command);
}

const setConfig = async(centraleId:string, track: number, centrale: CentraleData) =>
{
  const orderedTrack = [...centrale.tracks]
    .sort((a, b) => a.track - b.track)

    orderedTrack[track].expectsReturnCurrent = !orderedTrack[track].expectsReturnCurrent;
  
  let params: string[] = [];

  params[0] = ""
  for (let i = 0; i < 8; i++) {
     params[0] += orderedTrack[i].expectsReturnCurrent ? '1' : 0; 
  }

  params[1] = ""
  for (let i = 0; i < 8; i++) {
    params[1] += orderedTrack[i+8].expectsReturnCurrent ? '1' : 0;
  }
  
  const command: Command = 
  {
    commandName: CommandName.SET_CONFIG_WEB,
    parameters: params
  };

  return await sendCommand(centraleId,command);
}

const startMannual = async (centraleId:string, track: number, time: number, delay: number): Promise<CommandResponse> =>
{
    if(
      track < 0 
      || track > 15
      || time < 1
      || delay < 0
      )
    {
      return {
        color: "error",
        message: "Paramètres invalide"
      }
    }

    const command: Command =
    {
      commandName: CommandName.START_MANUAL,
      parameters: [`${track}`,`${time}`,`${delay}`]
    }

    return await sendCommand(centraleId,command);  
}

export const commandService = {
  startMannual,
  startDumpFile,
  startClockSync,
  setConfig,
  startCleanFiles
};
