import axios from 'axios';

import { BASE_URL } from '.';

async function getJwtToken(googleToken: string): Promise<string | undefined> {
    var result = await axios.post<{jwt:string}>(`${BASE_URL}/auth/google`, { token: googleToken });

    if(result.status != 200)
    {
      return undefined;
    }

    return result.data.jwt;
        
}

export const authService = {
  getJwtToken
};