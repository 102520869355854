import React from 'react';
import { Button, TextField, Typography, Paper } from '@mui/material';
import styled from '@emotion/styled';

const StyledContainer = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f4f6f8;
    text-align: -webkit-center;
`;

const StyledPaper = styled(Paper)`
    padding: 32px 48px;
    width: 400px;
`;

export const CenteredTypography = styled(Typography)`
    text-align: center;
`;


interface LoginProps {
  children: React.ReactNode;
  title?: string
}

const BasicPage: React.FC<LoginProps> = ({ children, title = 'Relek' }) => {
  return (
    <StyledContainer>
      <StyledPaper>
        <CenteredTypography variant="h4" gutterBottom>
          {title}
        </CenteredTypography>
        {children}
      </StyledPaper>
    </StyledContainer>
  );
};

export default BasicPage;