import React, { useState } from 'react';
import { createHashRouter, RouterProvider, RouteObject } from 'react-router-dom';

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import CentralesManager from './components/centralesManager';
import { GoogleLogin } from '@react-oauth/google';

import { ApiClientService, authService } from './services'

import { useGlobalState } from './GlobalStateContext';
import BasicPage, { CenteredTypography } from './components/basicPage';
import { red } from '@mui/material/colors';
import LogoutButton from './components/logout';
import { CentralesSetup } from './components/centraleSetup';

const App: React.FC = () => {
  const { logged, setLogged } = useGlobalState();

  const googleLogin = <BasicPage><GoogleLogin
    onSuccess={async credentialResponse => {
      const jwtToken = await authService.getJwtToken(credentialResponse.credential ?? '');
      if (jwtToken && jwtToken.length > 0) {
        ApiClientService.setToken(jwtToken);
        setLogged(true);
      }
    }}
    onError={() => {
      console.log('Login Failed');
    }}
    useOneTap
  /></BasicPage>;


  const authenticatedRoutes: RouteObject[] = [
    {
      path: '/',
      element: <CentralesManager />,
    }, {
      path: '/login',
      element: googleLogin
    },
    {
      path: '/no-access',
      element: <BasicPage>
        <CenteredTypography variant="h6" color={red}>Accès refusé
        </CenteredTypography>
      </BasicPage>
    },
    {
      path: '/setup',
      element: <CentralesSetup />
    }
    ,
    {
      path: '*',
      errorElement: <div>ERROR ELEMENT</div>,
      element: <BasicPage>NOT FOUND</BasicPage>
    }
  ];

  const unauthenticatedRoutes: RouteObject[] = [{
    path: '/setup',
    element: <CentralesSetup />
  },
  {
    path: '*',
    errorElement: <div>ERROR not logged</div>,
    element: googleLogin
  }];

  const router = createHashRouter(logged ? authenticatedRoutes : unauthenticatedRoutes);

  const theme = createTheme({
    palette: {
      background: {
        default: 'lightgray'
      },
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {logged && <LogoutButton />}
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
export default App;