import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { ApplicationServiceInterface } from '../types';
import { BASE_URL } from '.';
import { isExpired } from 'react-jwt';

let token: string | null = null;
let controller = new AbortController();
let axiosInstance: AxiosInstance;

const initialize = (): void | Promise<void> => {
  initializeAxios();
};

const initializeAxios = (): void => {
  axiosInstance = axios.create({
    signal: controller.signal,
    baseURL: BASE_URL,
    timeout: 2000,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        unsetToken();
        window.location.href = '/';
      }
      if (error.response && error.response.status === 403) {
        window.location.href = '/#/no-access';
      }
      return Promise.reject(error);
    }
  );

  const storedToken = localStorage.getItem('jwtToken');

  if (storedToken !== null && !isExpired(storedToken)) {
    console.log(`Token is still Valid`);
    setToken(storedToken);
  }
};

const setToken = (newToken: string): void => {
  token = newToken;
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  localStorage.setItem('jwtToken', newToken);
};

const unsetToken = (): void => {
  token = null;
  axiosInstance.defaults.headers.common.Authorization = false;
  localStorage.removeItem('jwtToken');
};

const abort = (): void => {
  controller.abort();
};

const getAxios = (): AxiosInstance => {
  if (controller.signal.aborted) {
    controller = new AbortController();
    axiosInstance.defaults.signal = controller.signal;
  }
  return axiosInstance;
};

const hasToken = (): boolean => token !== null && token.length > 0;

const ApiClientService = {
  initialize,
  setToken,
  unsetToken,
  hasToken,
  abort,
  get axios() {
    return getAxios();
  },
};

export default ApiClientService;