/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

interface SVGProps extends React.HTMLProps<HTMLDivElement> {
  backgroundColor: string;
  svgName?: string;
  pxSize?: number;
}

interface InnerSvgProps extends React.HTMLProps<HTMLDivElement> {
  bgColor: string;
  pxSize: number;
}

const StyledSvgContainer = styled.div<InnerSvgProps>`
  background-color: ${props => props.bgColor};
  width: ${props => props.pxSize}px;
  height: ${props => props.pxSize}px;
  border-radius: 15%;
  overflow: hidden;
  display:flex;
  align-items:center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4),
  0px 1px 2px rgba(0, 0, 0, 0.4);
transition: box-shadow 0.3s ease-in-out;

&:hover {
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4),
    0px 2px 4px rgba(0, 0, 0, 0.8);
transition: box-shadow 0.3s ease-in-out;
  
`;

const Svg: React.FC<SVGProps> = ({ backgroundColor, svgName, pxSize = 15, ...rest }) => {
  const path = `/${svgName}.svg`;

  return (
    <StyledSvgContainer bgColor={backgroundColor} pxSize={pxSize} onClick={rest.onClick} onContextMenu={rest.onContextMenu}>
      <img src={path} width={pxSize} height={pxSize} title={rest.title} />
    </StyledSvgContainer>
  );
}

export default Svg;