import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApiClientService } from './services';
import { GlobalStateProvider } from './GlobalStateContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderApplication = () => {
  root.render(

    <React.StrictMode>
      <GoogleOAuthProvider clientId="291756568082-hv7r8g4q2ad0m56dbftp0unhr8o67jh3.apps.googleusercontent.com">
        <GlobalStateProvider initLogged={ApiClientService.hasToken()}>
          <App />
        </GlobalStateProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  );
}

const initializeServices = () => (Promise.all(
  [ApiClientService.initialize()]
));

initializeServices()
  .then(renderApplication);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
