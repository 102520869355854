import { Alert, AlertColor } from '@mui/material';
import React, { SyntheticEvent, createContext, useContext, useState } from 'react';
import { SnackBar, SnackBarProps } from './components/snackBar';

interface GlobalStateContextProps {
  logged: boolean;
  setLogged: React.Dispatch<React.SetStateAction<boolean>>;
  snackBar: SnackBarProps;
  setSnackBar: React.Dispatch<React.SetStateAction<SnackBarProps>>
}

interface GlobalStateProviderProps {
  children: React.ReactNode;
  initLogged: boolean;
}



const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(undefined);


export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children, initLogged }) => {
  const [logged, setLogged] = useState<boolean>(initLogged);
  const [snackBar, setSnackBar] = useState<SnackBarProps>({ visible: false, message: '', severity: "success" });

  return (
    <GlobalStateContext.Provider value={{ logged, setLogged, snackBar, setSnackBar }}>
      {children}
      {snackBar.visible && <SnackBar {...snackBar}></SnackBar>}
    </GlobalStateContext.Provider >

  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};