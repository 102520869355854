import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import Centrale from './centrale';
import { CentraleData } from '../types';
import { centraleService } from '../services';

const isOnline = (centrale: CentraleData): boolean => new Date().getTime() - new Date(centrale.timestamp).getTime() < 20000;

const CentralesManager: React.FC = () => {

  const [centrales, setCentrales] = useState<CentraleData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);


  useEffect(() => {
    const fetchData = () => {
      centraleService.getCentrales()
        .then(data => {
          setCentrales(data);
          setLoading(false);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
    };

    // Fetch data immediately on component mount
    fetchData();

    // Set up an interval to fetch data every 10 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup: clear the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      height="100vh"
      width="100vw"
      color={'black'}
    >
      <Grid container spacing={1} columns={1} margin={1}>
        {centrales.map((centrale, index) => (
          <Grid sx={{ opacity: isOnline(centrale) ? 1 : 0.2 }} key={index + 'grid'} item>
            <Centrale key={index}  {...centrale} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default CentralesManager;