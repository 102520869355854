export interface TrackData {
  track: number;
  state: RelayState;
  hasReturnCurrent: boolean;
  expectsReturnCurrent: boolean;
  isInit: boolean;
}

export interface CentraleData {
  id: string;
  version: string;
  rtcStatus: boolean;
  sdStatus: boolean;
  tracks: Array<TrackData>;
  timestamp: Date;
  merlinStatus: boolean;
  temperature: number;
  centraleDetails: CentraleDetails;
}

interface CentraleDetails
{
    name:string;
    contact?:string;
    number?:string;
}

export enum RelayState {
  OFF = 0,
  ON = 1,
  STARTING = 2,
  TURNING_OFF = 3
}
