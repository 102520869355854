import { CentraleData } from '../types'
import { ApiClientService } from '.';

export const centraleService = {
    getCentrales
};

function getCentrales(): Promise<CentraleData[]> {
    return ApiClientService.axios.get<CentraleData[]>(`/centrales`)
        .then(response => {
            return response.data
            .sort((a,b) => {
                const aIsOnline = isOnline(a);
                const bIsOnline = isOnline(b);
                if(aIsOnline == bIsOnline)
                {
                    const aVal = parseInt(a.id);
                    const bVal = parseInt(b.id);

                    if(!aVal) return -1;
                    
                    return aVal - bVal;
                }
                
                return aIsOnline ? -1 : 1;
            });
        })
        .catch(error => {
            throw error;
        });
}

const  isOnline  = (centrale:CentraleData):boolean => new Date().getTime() - new Date(centrale.timestamp).getTime() < 20000;