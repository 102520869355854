import styled from "@emotion/styled";

interface CustomMenuProps {
  top: number;
  left: number;
  show: boolean;
}

export const CustomMenu = styled.div<CustomMenuProps>`
  position: fixed;
  opacity: 1;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1001;
  width: 200px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

export const MenuItem = styled.div`
  padding: 8px;
  &:hover {
    background-color: #eee;
  }
`;