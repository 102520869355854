import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ApiClientService } from "../services";
import { useGlobalState } from "../GlobalStateContext";

const FloatingButton = styled(Button)`
position: fixed;
padding: 0;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  min-width: 5px;
  background-color: rgb(0 0 0 / 20%);
  color: white; // Text color
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: rgb(0 0 0 / 80%);; // Slightly lighter shade on hover
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.3);
  }
`;

const LogoutButton: React.FC = () => {
  const { setLogged } = useGlobalState();

  const handleLogout = () => {
    ApiClientService.unsetToken();
    setLogged(false);
  };

  return (
    <FloatingButton variant="contained" color="secondary" onClick={handleLogout}>
      ➲
    </FloatingButton>
  );
};

export default LogoutButton;