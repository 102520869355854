import React, { useEffect, useState } from 'react';
import { Paper, Typography, Stack, Color, Snackbar, Alert, TextField, Box } from '@mui/material';

import Svg from './svg';

import styled from '@emotion/styled';
import { RelayState, TrackData } from '../types/Datas';
import { grey, red } from '@mui/material/colors';

import { CommandResponse, commandService } from '../services/commandService';
import { CustomMenu, MenuItem } from './rightClickMenu';
import { useGlobalState } from '../GlobalStateContext';
import { hover } from '@testing-library/user-event/dist/hover';

const TrackContainer = styled(Paper)<{ opacity: number }>(({ opacity }) => ({
  backgroundColor: 'antiquewhite',
  opacity: opacity
}));

const shadow = `box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4),
0px 1px 2px rgba(0, 0, 0, 0.4);
transition: box-shadow 0.3s ease-in-out;

&:hover {
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4),
  0px 2px 4px rgba(0, 0, 0, 0.8);
transition: box-shadow 0.3s ease-in-out;
}`

interface TrackNumberProps {
  size?: number;
  bgColor?: string;
  withoutShadow?: boolean;
}

const TrackNumber = styled.div<TrackNumberProps>`
  display:flex;
  height: ${props => props.size || 15}px;
  width: ${props => props.size || 15}px;
  border-style: solid;
  border-width: thin;
  border-color: black;
  background-color: ${props => props.bgColor || 'red'};
  color: white;
  font-size: x-small;
  justify-content: center;
  font-weight: bolder;
  align-items: center;
  border-radius: 15%;
  overflow: hidden;
  display:flex;
  align-items:center;
  ${props => props.withoutShadow || shadow}
  `;

const colorMapping = new Map<RelayState, string>([
  [RelayState.OFF, 'red'],
  [RelayState.ON, 'green'],
  [RelayState.STARTING, 'orange'],
  [RelayState.TURNING_OFF, 'orangeRed']
]);



const Track: React.FC<TrackData & { centraleId: string, setConfig: (track: number) => Promise<void> }> = ({ track, centraleId, state, hasReturnCurrent, expectsReturnCurrent, setConfig, isInit }) => {

  const [menuState, setMenuState] = useState<{ x: number; y: number; show: boolean, menuType: String }>({ x: 0, y: 0, show: false, menuType: '' });
  const { setSnackBar } = useGlobalState();
  const [duration, setDuration] = useState<string>('10');
  const [delay, setDelay] = useState<string>('3');


  const handleRightClick = (type: String) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setMenuState({ x: e.clientX, y: e.clientY, show: true, menuType: type });
  };


  const menuRef = React.useRef<HTMLDivElement>(null);

  const handleDocumentClick = (e: MouseEvent) => {
    if (menuState.show && menuRef.current && !menuRef.current.contains(e.target as Node | null)) {
      handleCloseMenu(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && menuState.show) {
      handleCloseMenu(true);
    }
  };

  // Set up the event listener when the menu is shown, and clean up when the component is unmounted
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [menuState.show]);

  const handleCloseMenu = async (cancel?: boolean) => {
    setMenuState({ x: 0, y: 0, show: false, menuType: '' })
    if (cancel) {
      return;
    }

    switch (menuState.menuType) {
      case 'output':
        const result = await commandService.startMannual(centraleId, track, +duration, +delay);
        setSnackBar({
          visible: true,
          message: result.message,
          severity: result.color
        });
        break;
      case 'retour':
        setConfig && setConfig(track);
        break;
    }

  };

  return (
    <TrackContainer opacity={isInit ? 1 : 0.2} >
      <Stack direction="row" spacing={1} padding={1} justifyContent={'center'}>
        <TrackNumber withoutShadow bgColor='black'>{track + 1}</TrackNumber>
        <Svg onContextMenu={handleRightClick('output')} key="output" svgName={'output'} backgroundColor={colorMapping.get(state) || 'red'} />
        <Svg key="input" svgName={'input'} backgroundColor={hasReturnCurrent ? 'green' : 'red'} />
        {expectsReturnCurrent ? <TrackNumber bgColor='green' onContextMenu={handleRightClick('retour')}>🆗</TrackNumber> : <TrackNumber onContextMenu={handleRightClick('retour')}>🛠️</TrackNumber>}
      </Stack>
      <CustomMenu ref={menuRef} show={menuState.show} top={menuState.y} left={menuState.x}>
        <MenuItem>
          {menuState.menuType === 'output' &&
            <Box display="flex" justifyContent="center">
              <TextField
                label="Délai"
                type="number"
                value={delay}
                onChange={(e) => setDelay(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                  max: 10,
                  step: 1,
                }}
              /><TextField
                label="Durée"
                type="number"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 5,
                  max: 60,
                  step: 5,
                }}
              />
            </Box>}
        </MenuItem>
        <MenuItem onClick={(e) => handleCloseMenu()}>{menuState.menuType === 'output' ? 'Start' : 'Switch'}</MenuItem>
        <MenuItem onClick={(e) => handleCloseMenu(true)}>Cancel</MenuItem>
      </CustomMenu>
    </TrackContainer >
  );
}

export default Track;