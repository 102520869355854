import { Grid, Box, Button, Checkbox, FormControlLabel, Typography, TextField, Divider } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import BasicPage from './basicPage';
import { read } from 'fs';

type CommandResult = { command?: number, parameters?: number[] };

class CommandFormatter {
  constructor(private cmd: CommandResult) { }

  ToString(): string {
    if (!this.cmd?.command || !this.cmd.parameters) {
      return "";
    }
    switch (this.cmd.command) {
      case 0xEE:
      case 0xbb:
      case 0x38:
        return String.fromCharCode(...this.cmd.parameters);
    }
    return "";
  }

  ToBooleanArray(): boolean[] {
    const string = this.ToString();

    const boolArray = new Array<boolean>();

    for (const char of string) {
      boolArray.push(char == '1' ? true : false);
    }
    return boolArray;
  }

  ToBool(): boolean {
    switch (this.cmd.command) {
      case 0x38:
        return this.ToString() === "true";
    }
    return false;
  }
}



const forbiddenBytes = [0x02, 0x03, 0x17, 0x00];

export const CentralesSetup: React.FC = () => {

  const [serialPort, setSerialPort] = useState<SerialPort | undefined>(undefined);
  const [serialOpen, setSerialOpen] = useState<boolean>(false);
  const [portInfo, setPortInfo] = useState<SerialPortInfo>();
  const [centraleId, setCentraleId] = useState<String>("");
  const [firmwareVersion, setFirmwareVersion] = useState<String>("");
  const [expectReturnCurrent, setExpectReturnCurrent] = useState(new Array<boolean>(16).fill(true));
  const [ssid, setSsid] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ ssid: false, password: false });
  const [wifiConnected, setWifiConnected] = useState(false);

  const handleBooleanChange = async (index: number) => {
    const newBooleans = [...expectReturnCurrent];
    newBooleans[index] = !newBooleans[index];

    await setCentraleConfig(newBooleans);
    await getCentraleConfig();
  };

  const connectToWifi = async () => {
    const ssidError = !ssid.trim();
    const passwordError = !password.trim();

    setErrors({ ssid: ssidError, password: passwordError });

    if (!ssidError && !passwordError) {
      const result = await sendCommand(
        0x41,
        0x30,
        true,
        [...ssid].map(char => char.charCodeAt(0)),
        [...password].map(char => char.charCodeAt(0))
      );

      if (result != undefined)
        if (result.ToBool()) {
          setWifiConnected(true);
          return true;
        }

      setWifiConnected(false);
    }

    return false;
  };

  useEffect(() => {
    const usbProductId = localStorage.getItem('usbProductId');
    const usbVendorId = localStorage.getItem('usbVendorId');

    if (serialPort && !serialOpen) {
      openPort();
      return;
    }

    if (usbProductId == undefined || usbVendorId == undefined) return;

    navigator.serial.getPorts()
      .then(ports => {
        const productId = parseInt(usbProductId);
        const vendorId = parseInt(usbVendorId);

        for (let port of ports) {
          const info = port.getInfo();

          if (info.usbProductId == productId && info.usbVendorId == vendorId) {
            setSerialPort(port);
            break;
          }
        };
      });

  }, [serialPort]);

  const openPort = async () => {
    console.info(serialPort);
    try {
      await serialPort?.open({
        baudRate: 9600
      });
    } catch (error) {
      alert(`Port Serial non accessible assurez vous d'avoir couper Merlin`);
      setSerialPort(undefined);
      return;
    }

    const info = serialPort?.getInfo();

    if (info?.usbProductId && info?.usbVendorId) {
      localStorage.setItem('usbProductId', `${info.usbProductId}`);
      localStorage.setItem('usbVendorId', `${info.usbVendorId}`);
    }

    setSerialOpen(true);

    await getCentraleId();

    await getfirmwareVersion();

    await getCentraleConfig();

    await getWifiConnected();
  }

  const setPort = async () => {

    try {
      const port = await navigator.serial.requestPort();
      const info = port?.getInfo();
      console.info(info);
      setSerialPort(port);
      setPortInfo(info);

    } catch (error) {
      alert(`Error in set port ${error}`);
    }
  };


  const getCentraleId = async () => {
    const result = await sendCommand(0x43, 0x30, true);
    if (result != undefined)
      setCentraleId(result.ToString());
  }

  const getfirmwareVersion = async () => {
    const result = await sendCommand(0x36, 0x30, true);
    if (result != undefined)
      setFirmwareVersion(result.ToString());
  }

  const getCentraleConfig = async () => {
    const result = await sendCommand(0x37, 0x30, true);
    if (result != undefined)
      setExpectReturnCurrent(result.ToBooleanArray());
  }

  const getWifiConnected = async () => {
    const result = await sendCommand(0x44, 0x30, true);
    if (result != undefined)
      setWifiConnected(result.ToBool());
  }

  const BoolArrayToByteArray = (bitsAsBoolean: boolean[]) => {
    let result: number[] = [];

    for (let i = 0; i < 8; i++) {
      result.push(bitsAsBoolean[i] ? '1'.charCodeAt(0) : '0'.charCodeAt(0));
    }

    return result;
  }

  const setCentraleConfig = async (newConfig: boolean[]) => {
    return await sendCommand(0x38, 0x30, false, BoolArrayToByteArray(newConfig.slice(0, 8)), BoolArrayToByteArray(newConfig.slice(8)));
  }

  const sendCommand = async (command: number, address: number, awaitResponse: boolean, ...args: number[][]): Promise<CommandFormatter | undefined> => {
    const writer = serialPort?.writable?.getWriter();
    if (!serialPort || !writer) {
      alert("Port serial non accessible, assurez vous d'avoir couper Merlin");
      return;
    }

    if (args.length > 5) {
      throw new Error("Can't send more than 5 argument parameters");
    }

    console.info(`Sending command ${command}`)

    try {
      let data: number[] = [
        0x02,
        address,
        command,
        0x30 + args.length
      ];

      if (args.some(arg => arg.some(b => forbiddenBytes.includes(b)))) {
        throw new Error("Unauthorized char in command");
      }

      for (let argument of args) {
        let length = argument.length;
        data.push(0x30 + Math.floor(length / 10));
        data.push(0x30 + (length % 10));
        data.push(...argument);
      }

      data.push(0x03);

      await writer.write(new Uint8Array(data));

      writer.releaseLock();

      if (!awaitResponse) return new CommandFormatter({});

      const reader = serialPort.readable?.getReader();

      if (!reader) {
        throw new Error('Port or writer is not initialized.');
      }


      let readArray: number[] = [];
      let result: ReadableStreamReadResult<Uint8Array> | undefined = undefined;
      do {
        result = await reader.read();
        if (result.value) {
          readArray = [...readArray, ...result.value];
        }

        if (result.value?.includes(0x02)) {
          console.log("starter bit detected");
        }

        if (result.value?.includes(0x03)) {
          console.log("end byte detected");
          break;
        }

      } while (!result.done);

      reader.releaseLock();

      if (readArray.length < 3) {
        new CommandFormatter({});
      }

      const response = readArray.slice(readArray.indexOf(0x02) + 1);

      const lastValue = response.pop();

      console.log(readArray);
      console.log(response);

      if (lastValue != 0x03) {
        console.log("wrong ending byte");
        new CommandFormatter({});
      }

      return new CommandFormatter({ command: response.shift(), parameters: [...response] });

    } catch (ex) {
      alert(ex);
      return new CommandFormatter({});
    }
  }


  if (serialPort === undefined) {
    return (<BasicPage><Button onClick={setPort}>Sélectionner le port serial</Button></BasicPage>);
  }

  return (<BasicPage title={`${centraleId} (${firmwareVersion})`}>
    <h2>{wifiConnected ? 'WiFi OK' : 'WiFi KO'}</h2>

    <form onSubmit={() => { }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            error={errors.ssid}
            helperText={errors.ssid ? "Nom du WiFi manquant" : ""}
            label="WiFi"
            variant="outlined"
            fullWidth
            value={ssid}
            onChange={(e) => setSsid(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            error={errors.password}
            helperText={errors.password ? "Mot de passe manquant" : ""}
            label="Mot de passe"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item>
          {wifiConnected ?
            <Button type='reset' onClick={connectToWifi} variant="contained" color="error" >
              Changer de WiFi
            </Button> :
            <Button onClick={connectToWifi} variant="contained" color="primary" >
              Connecter au WiFi
            </Button>
          }
        </Grid>
        <hr></hr>
        <Divider></Divider>
        <Grid item lg={16}>
          <Typography>Retour cabines attendus :</Typography>
          <Grid container >
            {expectReturnCurrent.map((bool, index) => (
              <Grid item key={index} >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bool}
                      onChange={() => handleBooleanChange(index)}
                    />
                  }
                  label={`${index + 1}`}
                  labelPlacement='bottom'
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </form>
  </BasicPage>)

}