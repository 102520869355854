import React, { useEffect, useState } from 'react';
import Track from './track';
import { Grid, Card, CardHeader, Avatar, IconButton, Paper, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CentraleData, CommandName } from '../types';

import stc from 'string-to-color';
import Svg from './svg';
import { Stack } from '@mui/system';
import { CustomMenu } from './rightClickMenu';
import { CommandResponse, commandService } from '../services/commandService';
import { useGlobalState } from '../GlobalStateContext';

const moment = require('moment');

const getTemperatureInDegree = (voltage: number): number => {
  // Define the reference voltage (in volts) and ADC resolution
  const V_ref = 5.0;
  const adcResolution = 1023;

  // Convert ADC value to voltage (in volts)
  const V_ADC = (voltage / adcResolution) * V_ref;

  // Convert voltage to temperature (in °C) based on MCP9700's characteristics
  const temperature = (V_ADC - 0.5) / 0.01;  // Note: 0.5V = 500mV and 0.01V/°C = 10 mV/°C

  return temperature;
}

const Centrale: React.FC<CentraleData> = (centrale) => {

  const { centraleDetails, id, tracks, version, timestamp, rtcStatus, sdStatus, merlinStatus, temperature } = centrale;

  const [menuState, setMenuState] = useState<{ x: number; y: number; show: boolean, command: CommandName | null, texts: string[] }>({ x: 0, y: 0, show: false, command: null, texts: [] });

  const { setSnackBar } = useGlobalState();
  const handleRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, command: CommandName, texts: string[]) => {
    e.preventDefault();
    setMenuState({ x: e.clientX, y: e.clientY, show: true, command, texts });
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (menuState.show && menuRef.current && !menuRef.current.contains(e.target as Node | null)) {
      handleCloseMenu(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && menuState.show) {
      handleCloseMenu(true);
    }
  };

  const getSetConfig = async (track: number) => {
    const result = await commandService.setConfig(id, track, centrale);
    setSnackBar({ visible: true, message: result.message, severity: result.color });
  }

  const degree = getTemperatureInDegree(temperature);

  const menuRef = React.useRef<HTMLDivElement>(null);

  // Set up the event listener when the menu is shown, and clean up when the component is unmounted
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [menuState.show]);

  const handleCloseMenu = async (isCancel?: boolean, file?: string) => {
    setMenuState({ x: 0, y: 0, show: false, command: null, texts: [] });

    let commandResponse: CommandResponse | undefined = undefined;
    if (isCancel) return;
    switch (menuState.command) {
      case CommandName.DUMP_FILE:
        if (file === "DELETE") {
          commandResponse = await commandService.startCleanFiles(id);
        }
        else if (file) {
          commandResponse = await commandService.startDumpFile(id, file);
        }
        break;
      case CommandName.SYNC_CLOCK:
        commandResponse = await commandService.startClockSync(id);
        break;
    }
    if (commandResponse !== undefined) {
      setSnackBar({ visible: true, message: commandResponse.message, severity: commandResponse.color });
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader sx={{ bgcolor: '#165bb2', color: 'white' }}
        avatar={
          <Avatar sx={{ bgcolor: stc(id), color: 'white' }} aria-label="recipe">
            {id}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <span>{centraleDetails.name}</span>
            <Svg onContextMenu={(e) => handleRightClick(e, CommandName.DUMP_FILE, ['relay.txt', 'command.txt', 'DELETE'])} svgName='sdcard' backgroundColor={sdStatus ? 'green' : 'red'}></Svg>
            <Svg onContextMenu={(e) => handleRightClick(e, CommandName.SYNC_CLOCK, ['Synchro horloge'])} svgName='rtc' backgroundColor={rtcStatus ? 'green' : 'red'} ></Svg>
            <Svg svgName='temperature' backgroundColor={degree < 40 ? 'green' : degree < 50 ? 'orange' : 'red'} title={`${degree.toFixed(2)} °C`}></Svg>
            <Svg svgName='merlin' backgroundColor={merlinStatus ? 'green' : 'red'}></Svg>
          </Stack>}
        subheader={`${version} - ${moment(timestamp).fromNow()}`}
      />
      <Grid container spacing={1} columns={8} padding={1} sx={{ backgroundColor: 'oldlace' }}>
        {tracks.map((track, index) => (
          <Grid key={`grid_${centraleDetails.name}_track_${index}`} item xs={4} md={2} lg={1} alignItems={'center'}>
            <Track setConfig={getSetConfig} centraleId={id} key={`${centraleDetails.name}_track_${index}`}  {...track} />
          </Grid>
        ))}
      </Grid>
      <CustomMenu ref={menuRef} show={menuState.show} top={menuState.y} left={menuState.x}>
        {menuState.texts?.map(text =>
          <MenuItem onClick={() => handleCloseMenu(false, text)}>
            {text}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleCloseMenu(true)}>
          Annuler
        </MenuItem>
      </CustomMenu>
    </Card>

  );
}

export default Centrale;