export enum CommandName {
  SET_CONFIG = "setConfig",
  SET_CONFIG_WEB = "setConfigWeb",
  SET_ID = "setId",
  REBOOT = "reboot",
  START_MANUAL = "startManual",
  STOP_MANUAL = "stopManual",
  DUMP_FILE = "dumpFile",
  SYNC_CLOCK = "syncClock",
  CLEAN_FILE_SYSTEM = "cleanFiles"
}